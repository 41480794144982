import React from "react"
import "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import Section from "@components/section"
import StaticHero from "@components/staticHero"
import { narcolepsySitemap } from "@utils/sitemapData"

const SiteMap = ({ location }) => {
  return (
    <Layout location={location} headerType="narcolepsy" footerType="narcolepsy">
      <Seo
        title="XYWAV User Site Map | XYWAV®"
        description="Explore the XYWAV.com site map. See XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero gradient="purple-red" alt="">
        <span tw="block (pt-6 pb-7)">Site map</span>
      </StaticHero>

      <Section tw="pt-12">
        <Container>
          <ul tw="text-base leading-[1.3] [>li]:last:mb-0">
            {narcolepsySitemap.map((link, i) => {
              return link.subLinks ? (
                <li key={i} tw="font-extrabold">
                  {link.text}
                  <ul tw="font-normal">
                    {link.subLinks.map((sublink, i) => {
                      return (
                        <li
                          key={i}
                          tw="mb-4 ml-6 xl:(mb-8) first:(mt-4 xl:(mt-8))"
                        >
                          <Anchor colour="merlin" link={sublink.url}>
                            {sublink.text}
                          </Anchor>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              ) : (
                <li key={i} tw="font-extrabold mb-4 xl:(mb-8)">
                  <Anchor colour="merlin" link={link.url}>
                    {link.text}
                  </Anchor>
                </li>
              )
            })}
          </ul>
        </Container>
      </Section>
    </Layout>
  )
}

export default SiteMap
